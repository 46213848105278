<template>
  <scroller
    :on-refresh="refresh"
    :on-infinite="loadMore"
  >
    <h1 class="text-primary mt-2">
      {{ $t('pages.campaign.title') }}
    </h1>

    <div class="mt-2 mb-2">
      <NavButtonBar class="pt-75 pb-25 d-flex align-items-center">
        <nav-button
          class="mr-75"
          color="primary"
          @action="$router.push({name : ROUTE_NAME_RESUME_ADD_CAMPAIGN})"
        >
          <i class="fa-solid fa-plus" />
        </nav-button>
        <span
          @click="$router.push({name : ROUTE_NAME_RESUME_ADD_CAMPAIGN})"
        >{{ $t('pages.campaign.add-edit.title-add') }}</span>
      </NavButtonBar>

      <template v-if="error">
        <div
          class="mb-5 text-muted text-center d-flex flex-column px-3"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.campaign.list.loading-error') }}

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="refresh(() => {})"
            >
              {{ $t('therms.retry') }}
            </TouchButton>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="!isFetch">
          <div class="d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else>
          <div
            v-if="has"
            class="animate__animated animate__fadeIn animate__fast"
          >
            <b-list-group>
              <b-list-group-item
                v-for="item in listSorted"
                :key="item.id"
                :item="item"
              />
            </b-list-group>
          </div>
          <div
            v-else
            class="mb-5 text-muted text-center d-flex flex-column px-3"
          >
            <h1 class="mt-3 mb-2">
              <i class="fa-solid fa-empty-set text-secondary" />
            </h1>
            {{ $t('pages.campaign.list.no-campaign') }}
          </div>
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import { BListGroup, BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import CampaignListItem from '@/views/pages/Campaigns/components/CampaignListItem.vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
// eslint-disable-next-line import/no-cycle
import { NAME_RESUME_ADD_CAMPAIGN } from '@/router/routes/campaigns'

export default {
  components: {
    NavButtonBar,
    NavButton,
    'b-list-group-item': CampaignListItem,
    BListGroup,
    BSpinner,
    TouchButton,
  },
  data() {
    return {
      // ui
      error: false,
    }
  },
  computed: {
    ...mapGetters({
      list: 'campaigns/getList',
      has: 'campaigns/hasOne',
      hasAll: 'campaigns/hasAll',
      count: 'campaigns/count',
      isFetch: 'campaigns/isFetch',
    }),
    ROUTE_NAME_RESUME_ADD_CAMPAIGN() {
      return NAME_RESUME_ADD_CAMPAIGN
    },
    listSorted() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.list
        .sort((a, b) => b.name - a.name)
        .sort((a, b) => new Date(b.start) - new Date(a.start))
        .sort((a, b) => new Date(b.end) - new Date(a.end))
    },
  },
  async mounted() {
    this.error = false

    try {
      if (!this.$store.getters['campaigns/isFetch']) {
        await this.$store.dispatch('campaigns/fetch', true)
      }
    } catch (err) {
      this.error = true
      throw err
    }
  },
  methods: {
    /**
     * @param finishToRefresh
     * @returns {Promise<void>}
     */
    async refresh(finishToRefresh) {
      this.error = false

      try {
        await this.$store.dispatch('campaigns/fetch', true)
        finishToRefresh()
      } catch (err) {
        this.error = true
        finishToRefresh()

        throw err
      }
    },
    /**
     * @param finishToLoadMore
     * @returns {Promise<void>}
     */
    async loadMore(finishToLoadMore) {
      // hides update status if no items are present
      if (!this.has || this.count < 10) {
        finishToLoadMore(false)
        return
      }

      try {
        finishToLoadMore(await this.$store.dispatch('campaigns/fetch'))
      } catch (err) {
        this.error = true
        finishToLoadMore(false)

        throw err
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

</style>
