<template>
  <b-list-group-item
    class="d-flex justify-content-between align-items-center"
    @action="$router.push({
      name: ROUTE_NAME_RESUME_ONE_CAMPAIGN,
      params : {
        campaign_id: item.id
      }
    })"
  >
    <span class="text-truncate pr-1"><strong class="pr-50">{{ item.name }}</strong> <span class="text-muted">({{ startDateFormat }} - {{ endDateFormat }})</span></span>
    <b-list-group-item-button
      disable
      color="primary"
    >
      <i class="fa-solid fa-angle-right" />
    </b-list-group-item-button>
  </b-list-group-item>

</template>

<script>
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import { NAME_RESUME_ONE_CAMPAIGN } from '@/router/routes/campaigns'

export default {
  components: {
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    startDateFormat() {
      return this.$moment(this.item.start).format('DD-MM-YYYY')
    },
    endDateFormat() {
      return this.$moment(this.item.end).format('DD-MM-YYYY')
    },
    ROUTE_NAME_RESUME_ONE_CAMPAIGN() {
      return NAME_RESUME_ONE_CAMPAIGN
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

</style>
